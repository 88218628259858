import React from 'react'
import {
  makeStyles,
  Grid,
  TextField,
  Paper,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  Slide,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { trackPromise } from 'react-promise-tracker'

import Logo from 'assets/logo.png'
import Bg from 'assets/bg.png'

import Api from 'service'
import { ApiResponse, SearchRequest, SearchResponse } from 'types'

const Component: React.FC = () => {
  const [messages, setMessages] = React.useState<Array<string>>([])
  const [filter, setFilter] = React.useState<SearchRequest>({ document: '', barCode: '' })
  const [data, setData] = React.useState<Array<SearchResponse> | null>(null)
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.layout}>
        <Slide in={true} direction='up'>
          <img src={Logo} alt='logo' className={classes.logo} />
        </Slide>
        <Slide in={true} direction='up'>
          <Alert variant='filled' severity='info'>
            Para realizar a pesquisa informe o número do CPF/CNPJ ou Código de Rastreio e clique em pesquisar.
          </Alert>
        </Slide>
        <Slide in={true} direction='up'>
          <Paper className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  id='document'
                  name='document'
                  label='CPF/CNPJ'
                  helperText='Informe apenas números'
                  fullWidth
                  inputProps={{ maxLength: 14 }}
                  value={filter.document}
                  onChange={val => setFilter({ ...filter, document: val.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  id='barCode'
                  name='barCode'
                  label='Código de Rastreio'
                  fullWidth
                  inputProps={{ maxLength: 18 }}
                  value={filter.barCode}
                  onChange={val => setFilter({ ...filter, barCode: val.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  fullWidth
                  startIcon={<Icon>search</Icon>}
                  disabled={
                    (filter.document.trim().length === 0 && filter.barCode.trim().length === 0) ||
                    (filter.document.trim().length !== 0 && filter.document.trim().length !== 11 && filter.document.trim().length !== 14) ||
                    (filter.barCode.trim().length !== 0 && filter.barCode.trim().length !== 18)
                  }
                  onClick={() => {
                    setData(null)
                    setMessages([])
                    trackPromise(
                      Api.search(filter).then((res: ApiResponse) => {
                        if (res.data.success && res.data.data) setData(res.data.data)
                        else {
                          if (Array.isArray(res.data.data)) {
                            setMessages(
                              res.data.data.map((x: any) => {
                                return x.message
                              }),
                            )
                          } else {
                            setMessages(['Pedido não localizado'])
                          }
                          setData(null)
                        }
                        setFilter({ document: '', barCode: '' })
                      }),
                    )
                  }}>
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Slide>
        {data && (
          <Slide in={true} direction='up'>
            <Paper className={classes.paper}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Razão Social</TableCell>
                      <TableCell>Nº da Nota Fiscal</TableCell>
                      <TableCell>Nº do Pedido</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.map((val, index) => (
                        <TableRow key={index}>
                          <TableCell>{val.clientName}</TableCell>
                          <TableCell>{val.fiscalNoteNumber}</TableCell>
                          <TableCell>{val.orderNumber}</TableCell>
                          <TableCell>
                            <Button
                              variant='contained'
                              color='primary'
                              startIcon={<Icon>launch</Icon>}
                              onClick={() => window.open('/#/' + val.barCode, '_blank')}>
                              Detalhes
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Slide>
        )}

        {messages.length > 0 &&
          messages.map((val, index) => (
            <Slide key={index} in={true} direction='up'>
              <Alert variant='filled' severity='warning' className={classes.message}>
                {val}
              </Alert>
            </Slide>
          ))}
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  layout: {
    width: '90%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xl')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    padding: theme.spacing(1),
  },
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(3),
    },
  },
  table: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundImage: `url(${Bg})`,
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}))

export default Component
