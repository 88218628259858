import * as React from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import { Backdrop, CircularProgress, makeStyles, createStyles, Theme } from '@material-ui/core'

import Logo from '../assets/logo.png'

const Loading: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker()
  const classes = useStyles()

  return !promiseInProgress ? null : (
    <Backdrop className={classes.backdrop} open={promiseInProgress}>
      <img src={Logo} alt='logo' className={classes.logo} />
      <CircularProgress color='inherit' />
    </Backdrop>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      marginBottom: 30,
    },
    backdrop: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
)

export default Loading
