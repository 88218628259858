import React from 'react'
import { useParams } from 'react-router-dom'
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tabs,
  Tab,
  Box,
  Typography,
  TextField,
  Grid,
  Slide,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { format, parseISO } from 'date-fns'

import Logo from 'assets/logo.png'
import Bg from 'assets/bg.png'

import Api from 'service'
import { GetResponse, ApiResponse } from 'types'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

interface ParamsProps {
  barCode: string
  clientCnpj: string
  fiscalNoteNumber: string
  orderNumber: string
}

const Component: React.FC = () => {
  const [value, setValue] = React.useState<number>(0)
  const [messages, setMessages] = React.useState<Array<string>>([])
  const [data, setData] = React.useState<GetResponse | null>(null)
  const { barCode, clientCnpj, fiscalNoteNumber, orderNumber } = useParams<ParamsProps>()
  const { promiseInProgress } = usePromiseTracker()
  const classes = useStyles()

  React.useEffect(() => {
    if (barCode || clientCnpj || fiscalNoteNumber || orderNumber) {
      setData(null)
      setMessages([])
      trackPromise(
        Api.get({
          barCode,
          clientCnpj,
          fiscalNoteNumber,
          orderNumber,
        }).then(
          (res: ApiResponse) => {
            if (res.data.success && res.data.data) setData(res.data.data)
            else {
              if (Array.isArray(res.data.data)) {
                setMessages(
                  res.data.data.map((x: any) => {
                    return x.message
                  }),
                )
              } else {
                setMessages(['Pedido não localizado'])
              }
              setData(null)
            }
          },
          res => {
            if (res?.toString().indexOf('Network Error') > -1)
              setMessages(['Não foi possível realizar a conexão, verifique sua internet e tente novamente'])
            else setMessages(['Um erro ocorreu ao realizar a pesquisa'])
          },
        ),
      )
    }
  }, [barCode, clientCnpj, fiscalNoteNumber, orderNumber])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div role='tabpanel' hidden={value !== index} id={`nav-tabpanel-${index}`} aria-labelledby={`nav-tab-${index}`} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.layout}>
        <Slide in={true} direction='up'>
          <img src={Logo} alt='logo' style={{ marginBottom: 10 }} className={classes.logo} />
        </Slide>

        {data && data.histories && data.histories.length > 0 && (
          <>
            <Slide in={true} direction='up'>
              <Paper className={classes.paper}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3} md={3} lg={3} className={classes.item}>
                    <TextField label='Código de Rastreio' variant='outlined' disabled={true} size='small' value={data.barCode} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} className={classes.item}>
                    <TextField label='Nota Fiscal' variant='outlined' disabled={true} size='small' value={data.fiscalNoteNumber ?? ''} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} className={classes.item}>
                    <TextField label='Pedido' variant='outlined' disabled={true} size='small' value={data.orderNumber} fullWidth />
                  </Grid>
                  {data.dateProvided && (
                    <Grid item xs={12} sm={3} md={3} lg={3} className={classes.item}>
                      <TextField
                        label='Previsão de Entrega'
                        variant='outlined'
                        disabled={true}
                        size='small'
                        value={data.dateProvided ? format(parseISO(data.dateProvided.toString()), "dd'/'MM'/'yyyy") : ''}
                        fullWidth
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={12} lg={12} className={classes.item}>
                    <TextField label='Nome Fantasia/Razão Social' variant='outlined' disabled={true} size='small' value={data.clientName} fullWidth />
                  </Grid>
                </Grid>
              </Paper>
            </Slide>

            <Slide in={true} direction='up'>
              <Tabs value={value} onChange={handleChange} variant='fullWidth' indicatorColor='primary' textColor='primary' className={classes.tabs}>
                <Tab label='HISTÓRICO' disabled={!data} />
                <Tab label='DADOS DA ENTREGA' disabled={!data} />
              </Tabs>
            </Slide>
          </>
        )}

        {promiseInProgress && (
          <Slide in={true} direction='up'>
            <Alert variant='filled' severity='info'>
              Localizando informações, aguarde...
            </Alert>
          </Slide>
        )}

        {messages.length > 0 &&
          messages.map((val, index) => (
            <Slide key={index} in={true} direction='up'>
              <Alert variant='filled' severity='warning' className={classes.message}>
                {val}
              </Alert>
            </Slide>
          ))}

        {data && data.histories && data.histories.length > 0 && (
          <>
            <TabPanel value={value} index={0}>
              <Slide in={true} direction='up'>
                <Paper className={classes.paper} elevation={2}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center'>
                            <Typography color='primary' className={classes.tableHeader}>
                              Data/Hora
                            </Typography>
                          </TableCell>
                          <TableCell align='center'>
                            <Typography color='primary' className={classes.tableHeader}>
                              Descrição
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.histories &&
                          data.histories
                            .sort((a, b) => (a.date > b.date ? -1 : 1))
                            .map((val, index) => (
                              <TableRow key={index}>
                                <TableCell align='center' style={{ width: '50%' }}>
                                  {format(parseISO(val.date.toString()), "dd'/'MM'/'yyyy' 'HH:mm")}
                                </TableCell>
                                <TableCell align='center' style={{ width: '50%' }}>
                                  {val.description}
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Slide>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Slide in={true} direction='up'>
                <Paper className={classes.paper}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={8} className={classes.item}>
                      <TextField label='Destinatário' variant='outlined' disabled={true} size='small' value={data.name ?? ''} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={2} className={classes.item}>
                      <TextField label='Cpf/Cnpj' variant='outlined' disabled={true} size='small' value={data.document ?? ''} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={2} className={classes.item}>
                      <TextField label='IE' variant='outlined' disabled={true} size='small' value={data.ie ?? ''} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes.item}>
                      <TextField
                        label='Endereço'
                        variant='outlined'
                        disabled={true}
                        size='small'
                        value={data.street ?? ''}
                        fullWidth
                        multiline
                        rows={2}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3} md={3} lg={2} className={classes.item}>
                      <TextField label='Número' variant='outlined' disabled={true} size='small' value={data.number ?? ''} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={9} md={9} lg={10} className={classes.item}>
                      <TextField label='Complemento' variant='outlined' disabled={true} size='small' value={data.complement ?? ''} fullWidth />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={4} lg={4} className={classes.item}>
                      <TextField label='Bairro' variant='outlined' disabled={true} size='small' value={data.neighborhood ?? ''} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} className={classes.item}>
                      <TextField label='Cidade' variant='outlined' disabled={true} size='small' value={data.city ?? ''} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2} md={1} lg={1} className={classes.item}>
                      <TextField label='Estado' variant='outlined' disabled={true} size='small' value={data.state ?? ''} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={3} className={classes.item}>
                      <TextField label='Cep' variant='outlined' disabled={true} size='small' value={data.zip ?? ''} fullWidth />
                    </Grid>
                  </Grid>
                </Paper>
              </Slide>
            </TabPanel>
          </>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  layout: {
    width: '90%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xl')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    padding: theme.spacing(1),
  },
  logo: {
    marginTop: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(1),
  },
  header: {
    borderRadius: '4px',
    marginBottom: theme.spacing(1),
  },
  table: {
    width: '100%',
  },
  tableHeader: {
    fontWeight: 500,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  tabs: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundImage: `url(${Bg})`,
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}))

export default Component
