import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'

import Search from './pages/search'
import Get from './pages/get'
import NotFound from './pages/not-found'

const App: React.FC = () => {
  return (
    <HashRouter>
      <Switch>
        <Route path='/:barCode' exact component={Get} />
        <Route path='/:clientCnpj/:fiscalNoteNumber/nf' exact component={Get} />
        <Route path='/:clientCnpj/:orderNumber/pedido' exact component={Get} />
        <Route path='/' exact component={Search} />
        <Route component={NotFound} />
      </Switch>
    </HashRouter>
  )
}

export default App
