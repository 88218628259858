import axios from 'axios'

import { ApiResponse, SearchRequest, GetRequest } from 'types'

const protocol = window.location.protocol.indexOf('https') > -1 ? 'https://' : 'http://'
const api = axios.create({
  baseURL: window.location.host.indexOf('localhost') >= 0 ? 'http://localhost:7071' : `${protocol}fm-tracking.azurewebsites.net`,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
  },
})

const Api = {
  get: (request: GetRequest): Promise<ApiResponse> => api.post(`/api/get`, request),
  search: (request: SearchRequest): Promise<ApiResponse> => api.post(`/api/search`, request),
}

export default Api
